<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <a-drawer title="小车质损流程" width="85%" :placement="placement" :closable="true" :visible="visible" :maskClosable="false"
    @close="onSwitch(false)" v-if="visible">
    <div class="drawer-div-top">
      <!-- 小车信息 -->
      <div>
        <div class=" suy_bod_b">
          <div class="suy_title textindent">
            <h2>车辆信息</h2>
          </div>
          <div class="h"></div>
        </div>
        <div class="suy_table_title">
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :span="6">
                <b>车牌: </b>
                <span class="qualityLoss-div-top-b">{{ vehicles.vinNo }}</span>
              </a-col>
              <a-col :span="6">
                <b>车型: </b>
                <span>{{ vehicles.brand }}</span>
              </a-col>
              <a-col :span="6">
                <b>销售: </b>
                <span>{{ vehicles.salesman }}</span>
              </a-col>
              <a-col :span="6">
                <b>合同金额: </b>
                <span>{{ vehicles.contractAmt }}</span>
              </a-col>
              <a-col :span="6">
                <b>客户: </b>
                <span>{{ vehicles.customerName }}</span>
              </a-col>
              <a-col :span="6">
                <b>时间： </b>
                <span>{{ vehicles.createTime }}</span>
              </a-col>
              <a-col :span="6">
                <b>起运地： </b>
                <span>{{ vehicles.startAddress }}</span>
              </a-col>
              <a-col :span="6">
                <b>目的地： </b>
                <span>{{ vehicles.endAddress }}</span>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
      <div class="h"></div>
      <!-- 运输车信息 -->
      <duv>
        <a-table :bordered='true' :dataSource="dataSourceTransport" :columns="columnsTransport" size='small'
          :pagination='false' :rowKey="(record, index) => { return index }" />
      </duv>
    </div>
    <div style=" padding: 24px;">
      <div class="h"></div>
      <!-- //责任内容 -->
      <div>
        <a-form ref="formRef" :model="qualityLossForm" :rules="rules">
          <a-row :gutter="[24]">
            <a-col :span="8">
              <a-form-item label="质&nbsp&nbsp损&nbsp&nbsp金&nbsp&nbsp额&nbsp" name="qualityLossAmt">
                <a-input v-model:value="qualityLossForm.qualityLossAmt" placeholder="请填写金额" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="质&nbsp&nbsp损&nbsp&nbsp类&nbsp&nbsp型&nbsp" name="qualityLossType">
                <a-checkbox-group v-model:value="qualityLossForm.qualityLossType">
                  <a-row :gutter="[24]">
                    <a-col :span="4" v-for="item in qualityLossTypeList " :key="item.value"
                      v-model:checked="qualityLossForm.qualityLossType">
                      <a-checkbox :value="item.value">{{ item.label }}</a-checkbox>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="是否保险理赔" name="isClaim">
                <a-select v-model:value="qualityLossForm.isClaim" @change="isClaimChange" placeholder="选择是否理赔">
                  <a-select-option :value="1">是</a-select-option>
                  <a-select-option :value="0">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8" v-if="qualityLossForm.isClaim === 1">
              <a-form-item>
                <a-select v-model:value="qualityLossForm.insuranceCompany" placeholder="选择保险公司">
                  <a-select-option value="太平洋">太平洋</a-select-option>
                  <!-- <a-select-option value="锦泰">锦泰</a-select-option> -->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8" v-if="qualityLossForm.isClaim === 1">
            </a-col>
            <a-col :span="16" v-else>
            </a-col>
            <a-col :span="8">
              <a-form-item label="质损结算方式" name="lossType">
                <a-select v-model:value="qualityLossForm.lossType" @change="lossTypeChange" placeholder="请选择结算方式">
                  <a-select-option :value="0">现金</a-select-option>
                  <a-select-option :value="1">抵扣</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="16">
            </a-col>
            <a-col :span="24">
              <a-form ref="formRefLossType" :model="qualityLossForm" :rules="rulesLossType">
                <a-row :gutter="[24]">
                  <a-col :span="6" v-if="qualityLossForm.lossType === 0">
                    <a-form-item label="账号" name="accountNum">
                      <a-input v-model:value="qualityLossForm.accountNum" placeholder="账号" class="form-iput-Atm" />
                    </a-form-item>
                  </a-col>
                  <a-col :span="6" v-if="qualityLossForm.lossType === 0">
                    <a-form-item label="户名" name="accountName">
                      <a-input v-model:value="qualityLossForm.accountName" placeholder="户名" class="form-iput-Atm" />
                    </a-form-item>
                  </a-col>
                  <a-col :span="6" v-if="qualityLossForm.lossType === 0">
                    <a-form-item label="开户行" name="accountBank">
                      <a-input v-model:value="qualityLossForm.accountBank" placeholder="开户行" class="form-iput-Atm" />
                    </a-form-item>
                  </a-col>
                  <a-col :span="6" v-if="qualityLossForm.lossType === 0">
                    <a-form-item label="付款账号类型" name="paymentAccountType">
                      <a-select ref="select" v-model:value="qualityLossForm.paymentAccountType" placeholder="付款账号类型"
                        class="form-iput-Atm">
                        <a-select-option value="公账">公账</a-select-option>
                        <a-select-option value="私账">私账</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="24" v-if="qualityLossForm.lossType === 0">
                    <a-form-item label="收款码">
                      <a-upload v-model:file-list="imgFileList" accept="image/*" list-type="picture"
                        :remove="handleRemove" :before-upload="beforeUpload">
                        <a-button :disabled="imgFileList.length > 0">
                          <CameraOutlined />
                          收款码
                        </a-button>
                      </a-upload>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </a-col>

            <a-col :span="24">
              <a-form-item
                label="备&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp注">
                <a-textarea v-model:value="qualityLossForm.remark" :rows="2" :maxlength="200" showCount />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="h"></div>
      <!-- //责任对象 -->
      <div>
        <div class="suy_title textindent">
          <h2>责任人对象： <a-button class="editable-add-btn" @click="handleAdd" style="margin-bottom: 8px" type="primary"
              danger>新增</a-button>
          </h2>
        </div>
        <div class="h"></div>
        <a-table bordered :data-source="dataSource" :columns="columns" size='small' :pagination='false'
          :rowKey="(record, index) => { return index }">
          <template #responsibilityType="{ record }">
            <a-select ref="select" v-model:value="record.responsibilityType" style="width:190px"
              @change='saveRecord(record)'>
              <a-select-option :value="0">承运商</a-select-option>
              <a-select-option :value="1">办事处</a-select-option>
              <a-select-option :value="2">业务部</a-select-option>
              <a-select-option :value="3">个人</a-select-option>
            </a-select>
          </template>
          <template #objectName="{ record }">
            <a-select ref="select" v-model:value="record.objectId" style="width:100%"
              v-if="record.responsibilityType === 0" @change="objectNameAssignment(record, 0)">
              <a-select-option v-for="item in carrierList " :key="item.value">{{ item.label }}</a-select-option>
            </a-select>
            <a-select ref="select" v-model:value="record.objectId" show-search style="width:100%"
              v-if="record.responsibilityType === 1" @change="objectNameAssignment(record, 1)">
              <a-select-option v-for="item in  orgOfficeList" :key="item.id">{{ item.name }}</a-select-option>
            </a-select>
            <a-select ref="select" v-model:value="record.objectId" style="width:100%"
              v-if="record.responsibilityType === 2" @change="objectNameAssignment(record, 2)">
              <a-select-option v-for="item in $store.state.app.orgList" :key="item.id">{{ item.name }}</a-select-option>
            </a-select>
            <a-input disabled v-if="record.responsibilityType === 3" />
          </template>
          <template #personName="{ record }">
            <a-select v-if="record.responsibilityType != 0" v-model:value="record.personId" style="width:100%" show-search
              :filter-option="false" @focus="handleFocus" option-filter-prop="children"
              :not-found-content="fetching ? undefined : null" @change="objectNameAssignment(record, 5)"
              @search="handleSearch">
              <a-select-option v-for="employee  in employees" :key="employee.id">{{ employee.name }}</a-select-option>
            </a-select>
            <a-input disabled v-else />
          </template>
          <template #amt="{ record }">
            <a-input v-model:value="record.amt" min='1' type='number' />
            <!-- <div class="editable-cell"> -->
            <!-- <div v-if="editableData[record.key]" class="editable-cell-input-wrapper"> -->
            <!-- <a-input v-model:value="editableData[record.key].amt" min='1' type='number' /> -->
            <!-- <check-outlined class="editable-cell-icon-check" @click="save(record.key)" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ record.amt }}
                <edit-outlined class="editable-cell-icon" @click="edit(record.key)" />
              </div> -->
            <!-- </div> -->
          </template>

          <template #operation="{ record }">
            <a-popconfirm v-if="dataSource.length" title="&nbsp&nbsp确认删除吗?&nbsp&nbsp" @confirm="onDelete(record.key)">
              <a>删除</a>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
      <a-button class="editable-add-btn" @click="submit" :loading="loading" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>
<script>
import { computed, defineComponent, reactive, toRefs } from 'vue'
import { message } from 'ant-design-vue'
import { vehicleDetail, vehicleTransDetail } from '@/api/transport/truck'
import { start } from '@/api/system/bpm/instance'
import { /* getOrgList, */ fileUpload as uploadBpm } from '@/api/marketing/modList'
import { useStore } from 'vuex'
// import { CheckOutlined, EditOutlined } from '@ant-design/icons-vue'
import { list } from '@/api/system/employee'
import { cloneDeep } from 'lodash-es'

export default defineComponent({
  components: {
    // CheckOutlined,
    // EditOutlined
  },
  setup () {
    const store = useStore()
    const state = reactive({
      formRef: null,
      formRefLossType: null,
      loading: false,
      visible: false,
      fetching: true,
      carid: '',
      imgFileList: [],
      listData: [],
      orgOfficeList: [],
      qualityLossTypeList: [{
        label: '车辆损坏',
        value: '车辆损坏'
      }, {
        label: '车辆违章',
        value: '车辆违章'
      }, {
        label: '车辆物品丢失',
        value: '车辆物品丢失'
      }, {
        label: '抵扣运费',
        value: '抵扣运费'
      }, {
        label: '买断车',
        value: '买断车'
      }, {
        label: '水淹车',
        value: '水淹车'
      }, {
        label: '验车失误',
        value: '验车失误'
      }, {
        label: '报价失误',
        value: '报价失误'
      }, {
        label: '超时',
        value: '超时'
      }, {
        label: '放空费',
        value: '放空费'
      }],
      vehicles: {
        vinNo: '',
        brand: '',
        salesman: '',
        contractAmt: '',
        customerName: '',
        createTime: '',
        startAddress: '',
        endAddress: ''
      },
      orgList: [],
      qualityLossForm: {},
      employees: [],
      columns: [
        {
          title: '对象类型',
          dataIndex: 'responsibilityType',
          width: '10%',
          slots: {
            customRender: 'responsibilityType'
          }
        },
        {
          title: '部门/同行',
          dataIndex: ' objectName',
          width: '10%',
          slots: {
            customRender: 'objectName'
          }
        },
        {
          title: '人员',
          dataIndex: 'personName',
          width: '10%',
          slots: {
            customRender: 'personName'
          }
        },
        {
          title: '承担金额',
          dataIndex: 'amt',
          width: '10%',
          slots: {
            customRender: 'amt'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '200',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      columnsTransport: [
        {
          title: '运输类型',
          dataIndex: 'transportType.label'
        },
        {
          title: '运输车牌',
          dataIndex: 'carNo'
        },
        {
          title: '承运商',
          dataIndex: 'carrierName'
        },
        {
          title: '运输费用',
          dataIndex: 'transportFee'
        },
        {
          title: '线路',
          dataIndex: 'lineName'
        }
      ],
      dataSourceTransport: [],
      dataSource: [],
      carrierList: [],
      rules: {
        qualityLossAmt: [{ required: true, message: '请填写金额', trigger: 'blur' }],
        qualityLossType: [{ required: true, message: '请选择类型' }],
        isClaim: [{ required: true, message: '请选择是否买保险', trigger: 'blur',type:'number'}],
        lossType: [{ required: true, message: '请选择结算类型' }]
      },
      rulesLossType: {
        accountNum: [{ required: true, message: '请填写账号', trigger: 'blur' }],
        accountName: [{ required: true, message: '请填写用户名' }],
        accountBank: [{ required: true, message: '请填写开户行', trigger: 'blur' }],
        paymentAccountType: [{ required: true, message: '请选择账号类型' }]
      },
      count: computed(() => state.dataSource.length + 1),
      editableData: reactive({})

    })

    const edit = key => {
      console.log('edit', key)
      state.editableData[key] = cloneDeep(state.dataSource.filter(item => key === item.key)[0])
    }
    const saveRecord = key => {
      key.objectName = ''
      key.objectId = ''
      key.personName = ''
      key.personId = ''
      key.transTruckId = ''
    }

    const save = key => {
      console.log('save', key)
      Object.assign(state.dataSource.filter(item => key === item.key)[0], state.editableData[key])
      delete state.editableData[key]
    }

    const onDelete = key => {
      state.dataSource = state.dataSource.filter(item => item.key !== key)
    }
    // state.orgList = store.state.app.orgList
    // getOrgList({}).then(res => { state.orgList = res.data })
    // getOrgList({ orgType: 1 }).then(res => { state.orgOfficeList = res.data })
    const handleAdd = () => {
      const newData = {
        key: `${state.count}`,
        responsibilityType: '',
        amt: 1,
        objectName: '',
        personName: ''
      }
      state.dataSource.push(newData)
    }
    const loadData = () => {
      vehicleDetail(state.carid, {}
      ).then((res) => {
        if (res.code === 10000) {
          console.log('vehicleDetail', res)
          state.vehicles = res.data
        }
      })
      vehicleTransDetail(state.carid).then((res) => {
        state.carrierList = []
        if (res.code === 10000) {
          console.log('vehicleTransDetail', res)
          state.dataSourceTransport = res.data
          for (let i = 0; i < res.data.length; i++) {
            state.carrierList.push({
              flag: res.data[i].totvId,
              label: res.data[i].carrierName,
              value: res.data[i].carrierId,
              transTruckId: res.data[i].transTruckId
            })
          }
        }
      })
    }
    const oncarid = (parameter, orgList) => {
      state.carid = parameter
      state.orgOfficeList = orgList
      loadData()
    }
    const handleChange = () => {
    }

    const onSwitch = (parameter) => {
      state.visible = parameter
    }
    const submit = () => {
      state.formRef
        .validate()
        .then(() => {
          if (state.qualityLossForm.lossType === 0) {
            state.formRefLossType
              .validate()
              .then(() => {
                qLoss()
              }).catch(error => {
                console.log('收款码', error)
                if (state.imgFileList.length > 0) {
                  const formData = new FormData()
                  state.imgFileList.forEach(file => {
                    formData.append('file', file.originFileObj)
                  })
                  uploadBpm(formData).then(res => {
                    if (res.code === 10000) {
                      state.qualityLossForm.fileId = res.data.id
                      qLoss()
                    }
                  })
                } else {
                  message.error('请上传收款码/或者填写完收款账号')
                }
              })
          } else {
            console.log('现金')
            state.qualityLossForm.fileId = ''
            qLoss()
          }
        }).catch(error => {
          console.log('失败', error)
        })
    }
    const qLoss = () => {
      state.loading = true
      if (state.dataSource.length > 0) {
        state.qualityLossForm.responsibilities = state.dataSource
        state.qualityLossForm.orderVehicleId = state.carid
        state.qualityLossForm.orderVehicleVin = state.vehicles.vinNo
        state.qualityLossForm.customerId = state.vehicles.customerId
        state.qualityLossForm.customerName = state.vehicles.customerName
        start({
          bizParams: state.qualityLossForm,
          flowId: state.qualityLossForm.fileId,
          type: 5
        }).then((res) => {
          if (res.code === 10000) {
            console.log('vehicleDetail', res)
            // state.vehicles = res.data
            message.info(res.msg)
            state.qualityLossForm = {}
            state.dataSource = []
            onSwitch(false)
          }
          state.loading = false
        })
      } else {
        state.loading = false
        message.error('请添加完善责任对象')
      }
    }
    const handleSelect = (value, option) => {
      state.employees.forEach(e => {
        if (e.id === value) {
          state.searchForm.creditor = e.name
        }
      })
    }
    const handleSearch = value => {
      const reg = /^[A-Za-z]+$/
      if (value !== '') {
        if (!reg.test(value)) {
          state.fetching = false
          list({ name: value }).then((res) => {
            if (res.code === 10000) {
              state.employees = res.data
              state.fetching = true
            }
          })
        }
      }
    }
    const objectNameAssignment = (record, item) => {
      switch (item) {
        case 0:
          console.log('record==', record)
          for (let i = 0; i < state.carrierList.length; i++) {
            if (state.carrierList[i].value === record.objectId) {
              record.objectName = state.carrierList[i].label
              record.transTruckId = state.carrierList[i].transTruckId
              console.log('record==', record)
              return
            }
          }

          break
        case 1:
          for (let i = 0; i < state.orgOfficeList.length; i++) {
            if (state.orgOfficeList[i].id === record.objectId) {
              record.objectName = state.orgOfficeList[i].name
              return
            }
          }
          break
        case 2:
          for (let i = 0; i < store.state.app.orgList.length; i++) {
            if (store.state.app.orgList[i].id === record.objectId) {
              record.objectName = store.state.app.orgList[i].name
              return
            }
          }
          break
        case 5:
          for (let i = 0; i < state.employees.length; i++) {
            if (state.employees[i].id === record.personId) {
              record.personName = state.employees[i].name
              return
            }
          }
          break
        default:
          message.success('出现错误' + item)
      }
    }
    const handleRemove = file => {
      const index = state.imgFileList.indexOf(file)
      const newFileList = state.imgFileList.slice()
      newFileList.splice(index, 1)
      state.imgFileList = newFileList
    }
    const beforeUpload = file => {
      state.imgFileList = [...state.imgFileList, file]
      return false
    }
    const lossTypeChange = (e,v) => {
      state.qualityLossForm.accountNum = null
      state.qualityLossForm.accountName = null
      state.qualityLossForm.accountBank = null
      state.qualityLossForm.paymentAccountType = null
    }
    const isClaimChange = (e,v) =>{
      state.qualityLossForm.insuranceCompany = null
    }
    return {
      ...toRefs(state),
      oncarid,
      submit,
      saveRecord,
      onSwitch,
      loadData,
      onDelete,
      isClaimChange,
      lossTypeChange,
      handleSelect,
      handleChange,
      handleSearch,
      handleAdd,
      edit,
      save,
      objectNameAssignment,
      handleRemove,
      beforeUpload
    }
  }
})
</script>
<style>
.ant-form-item {
  margin-bottom: 16px;
}

:deep(.ant-drawer-body) {
  padding: 0px;
}
</style>
