<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <a-drawer title="修改为承运商收款" width="30%" :visible="visible" @close="closeVisible()" destroyOnClose="true">
    <a-form ref="formRef" layout="vertical" :model="form" :rules="rules">
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="原因">
            <a-textarea v-model:value="reason" :rows="6" :maxlength="1000" showCount />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-button type="primary" @click="submitText">提交</a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-drawer>
</template>
<script>
import { computed, reactive, toRefs } from 'vue'
import { message } from 'ant-design-vue'
import { orderPickEdit } from '@/api/trans/ops/edit'

export default {
  props: {
    editDeliveryVisible: { type: Boolean, default: false },
    orderId: { type: String, default: '' },
    orderVehicleId: { type: String, default: '' }
  },
  setup (props, { emit }) {
    const state = reactive({
      visible: computed({
        get: () => props.editDeliveryVisible,
        set: val => emit('update:editDeliveryVisible', val)
      }),
      loading: false,
      reason: '',
      form: {
        orderId: computed({
          get: () => props.orderId,
          set: val => emit('update:orderId', val)
        }),
        orderVehicleId: computed({
          get: () => props.orderVehicleId,
          set: val => emit('update:orderVehicleId', val)
        }),
        endAddress: {}
      }
    })
    const submitText = () => {
      orderPickEdit({
        params: state.form,
        type: {
          value: 0
        },
        reason: state.reason,
        subType: 14
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.visible = false
        }
      })
    }
    const closeVisible = () => {
      state.visible = false
    }
    return {
      ...toRefs(state),
      submitText,
      closeVisible
    }
  }
}
</script>
<style>
.ant-form-item {
  margin-bottom: 16px;
}
:deep(.ant-drawer-body) {
  padding: 0px;
}
</style>
