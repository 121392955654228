<template>
  <a-drawer :title="title" width="50%" :placement="placement" :closable="true" :visible="visible" :maskClosable="false" @close="onSwitch(false)" v-if="visible">
    <suy-table :reload="loadData" v-model:columns="columns" >
      <!-- 2024年1月22日15:48:50 ZLP移除功能 -->
      <!-- <template #search>
        <a-form ref="formQueryRef" layout="inline" :rules="rules" :model="searchForm">
          <a-form-item label="名称" name=feeName>
            <a-input v-model:value="searchForm.feeName" />
          </a-form-item>
          <a-form-item label="费用" name=feeAmt>
            <a-input v-model:value="searchForm.feeAmt" />
          </a-form-item>
          <a-form-item label="员工" name="creditId">
            <a-select v-model:value="searchForm.creditId" class="ransport_search_input" show-search :filter-option="false" @focus="handleFocus" option-filter-prop="children" :not-found-content="fetching ? undefined : null" @change="handleChange" @select="handleSelect" @search="handleSearch">
              <a-select-option v-for="employee  in employees" :key="employee.id">{{employee.name}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="备注" style="margin:10px 0px 0px 10px">
            <a-textarea v-model:value="searchForm.remark" style="width:680px" :maxlength="200" showCount />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="onclick('',1)" style="position: fixed;margin:16px 0px 0px 10px;">
              <template #icon>
                <SearchOutlined />
              </template>添加
            </a-button>
          </a-form-item>
        </a-form>
      </template> -->
      <a-table :columns="[sortColumn,...columns]" :rowKey="(record,index)=>{return index}" :data-source="listData" :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #sort="{ index }">
          {{index+1}}
        </template>

      </a-table>
    </suy-table>
  </a-drawer>
</template>
<script>
import { toRefs, reactive, ref } from 'vue'
import { page, add } from '@/api/transport/additional'
import { list } from '@/api/system/employee'
import SuyTable from '@/components/SuyTable'
import { message } from 'ant-design-vue'
export default {
  components: {
    SuyTable
  },
  setup () {
    const state = reactive({
      formQueryRef: ref(),
      carid: '',
      fetching: true,
      employees: [],
      searchForm: {
        creditId: null,
        feeAmt: 0,
        feeName: '',
        employee: '',
        remark: ''
      },
      rules: {
        feeAmt: [{ required: true, message: '请填写费用', trigger: 'change' }],
        feeName: [{ required: true, message: '请填写附加费名称', trigger: 'change' }],
        employee: [{ required: true, message: '请选择员工', trigger: 'change' }]
      },
      visible: false,
      loading: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      listData: [],
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '员工名',
          dataIndex: 'creditor',
          key: 'creditor',
          ellipsis: true
        },
        {
          title: '费用名称',
          dataIndex: 'feeName',
          key: 'feeName',
          ellipsis: true
        },
        {
          title: '费用金额',
          dataIndex: 'feeAmt',
          key: 'feeAmt',
          ellipsis: true
        },

        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          ellipsis: true
        }
      ],
      form: {},
      getname: ''
    })
    const loadData = async () => {
      state.loading = true
      page(state.carid, {
        current: state.pagination.current,
        size: state.pagination.pageSize,
        name: state.searchForm.name
      }).then((res) => {
        state.listData = res.data.records
        state.pagination.current = res.data.current
        state.pagination.pageSize = res.data.size
        state.pagination.total = res.data.total
      })

      state.loading = false
    }
    const onSwitch = (parameter) => {
      if (parameter === false) {
        state.carLineId = -1
        state.carid = -1
        state.radioValue = 0
        state.caridLinename = null
      }
      state.visible = parameter
    }
    const oncarid = (parameter) => {
      state.carid = parameter
      loadData()
    }
    const onclick = (recode, type) => {
      switch (type) {
        case 1:
          state.formQueryRef
            .validate()
            .then(() => {
              add(state.carid, state.searchForm).then((res) => {
                if (res.code === 10000) {
                  message.success('添加成功')
                  loadData()
                }
              })
              state.searchForm.feeAmt = 0
              state.searchForm.feeName = ''
              state.searchForm.remark = ''
              state.formQueryRef.resetFields()
              loadData()
            }).catch(error => {
              console.log(error)
            })
          break

        default:
          message.success('出现错误' + type)
      }
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const handleSelect = (value, option) => {
      state.employees.forEach(e => {
        if (e.id === value) {
          state.searchForm.creditor = e.name
        }
      })
    }
    const handleChange = value => {
    }
    const handleSearch = value => {
      const reg = /^[A-Za-z]+$/
      if (value !== '') {
        if (!reg.test(value)) {
          state.fetching = false
          list({ name: value }).then((res) => {
            if (res.code === 10000) {
              state.employees = res.data
              state.employee = state.employee + ''
              state.fetching = true
            }
          })
        }
      }
    }
    const handleFocus = () => {
    }
    return {
      oncarid,
      handleChange,
      handleSelect,
      handleSearch,
      handleFocus,
      onSwitch,
      ...toRefs(state),
      loadData,
      handleTableChange,
      onclick
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
