import { putRequest, getRequest, postBodyRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/cis/employee/page', params)
}

export function add (params) {
  return postBodyRequest('/cis/employee/add', params)
}

export function edit (params) {
  return postBodyRequest('/cis/employee/edit', params)
}
export function office (params) {
  return postBodyRequest('/cis/employee/grant/office', params)
}

export function enable (id, isnable) {
  return putRequest(`/cis/employee/${id}/${isnable}`)
}

export function detail (id, params) {
  return getRequest(`/cis/employee/${id}`, params)
}
export function officeDetail (id, params) {
  return getRequest(`/cis/employee/${id}/office`, params)
}

export function dataAuth (id, params) {
  return getRequest(`/cis/employee/${id}/data`, params)
}

export function updateDataAuth (id, params) {
  return postBodyRequest(`/cis/employee/${id}/data/auth`, params)
}

export function dataRole (id, params) {
  return getRequest(`/cis/employee/${id}/role`, params)
}

export function updateDataRole (id, params) {
  return postBodyRequest(`/cis/employee/${id}/data/role`, params)
}

// 离职
export function isQuit (id, params) {
  return putRequest(`/cis/employee/${id}/isQuit`, params)
}
export function list (params) {
  return postBodyRequest('/cis/employee/list', params)
}

export function dataPosition (id, params) {
  return getRequest(`/cis/employee/${id}/position`, params)
}

export function updateDataPosition (id, params) {
  return postBodyRequest(`/cis/employee/${id}/data/position`, params)
}

export function deleteDataPosition (id) {
  return getRequest(`/cis/employee/${id}/position/delete`, {})
}

export function asynEmpOrgTree (parentOrgId, params) {
  return postBodyRequest(`/cis/employee/${parentOrgId}/asynEmpOrgTree`, params)
}
export function getOfficeAll (id, params) {
  return getRequest('/cis/employee/office/all', params)
}
