import {
  postBodyRequest
} from '@/utils/axios'

export function add (orderVehicleId, params) {
  return postBodyRequest(`/trans/order/vehicle/additional/${orderVehicleId}/add`, params)
}
export function page (orderVehicleId, params) {
  return postBodyRequest(`/trans/order/vehicle/additional/${orderVehicleId}/page`, params)
}
